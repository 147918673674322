// Dependencies
import React, { useEffect, useState } from 'react'

// Components
import { loadingSVG } from '../../assets/svgs'

// Styles
import '../../assets/index.css'
import {
  CountdownWrapper,
  CountdownBlock,
} from '../../components/Musicas/Musicas.styled'

export const Countdown = ({ date }) => {
  const [finished, setFinished] = useState(true)
  const [countdown, setCountdown] = useState({})
  useEffect(() => {
    if (date) {
      // Set the date we're counting down to
      let countDownDate = new Date(date + ' 00:00:00').getTime()

      // Update the count down every 1 second
      let interval = setInterval(function () {
        // Get today's date and time
        let now = new Date().getTime()

        // Find the distance between now and the count down date
        let distance = countDownDate - now

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24))
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        let seconds = Math.floor((distance % (1000 * 60)) / 1000)

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(interval)
          setFinished(true)
        } else if (finished) {
          setFinished(false)
        }
        setCountdown({
          days,
          hours,
          minutes,
          seconds,
        })
      }, 1000)
    }
  }, [date])

  const { days, hours, minutes, seconds } = countdown

  return !finished ? (
    <CountdownWrapper>
      {days || hours || minutes || seconds ? (
        <>
          <CountdownBlock>{`${days}d`}</CountdownBlock>
          <CountdownBlock>{`${hours}h`}</CountdownBlock>
          <CountdownBlock>{`${minutes}m`}</CountdownBlock>
          <CountdownBlock>{`${seconds}s`}</CountdownBlock>
        </>
      ) : (
        <CountdownBlock>
          <div
            className="loading"
            dangerouslySetInnerHTML={{ __html: loadingSVG }}
          />
        </CountdownBlock>
      )}
    </CountdownWrapper>
  ) : null
}
export default Countdown

// Dependencies
import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

// Styles
import { MusicasWrapper } from '../Musicas/Musicas.styled'

// Components
import Album from './Album'

// Helpers, Context
import { Context as PlayerContext } from '../../context/playerContext'
import { Context as MicroCopyContext } from '../../context/microcopyContext'
import { getMicroCopy } from '../../helpers/microcopy'

const Musicas = ({ albums, album, widget = false }) => {
  const { state } = useContext(MicroCopyContext)

  // Getting state and methods from Context
  const { setTracks } = useContext(PlayerContext)

  useEffect(() => {
    const tracks = {}
    albums.forEach((current, i) => {
      tracks[i] = []
      current.tracks.forEach((track, j) => {
        const trackConfig = {
          ...track,
          albumId: i,
          title: track.title,
          trackId: j,
          streamUrl: `https://api.soundcloud.com/tracks/${
            track.soundcloudTrackId
          }/stream?consumer_key=${
            process.env.GATSBY_SOUNDCLOUD_CONSUMER_KEY || ''
          }`,
        }

        tracks[i][j] = trackConfig
      })
    })
    setTracks(tracks)
  }, [])

  return (
    <div className="content">
      <MusicasWrapper>
        <div>
          <Album
            index={0}
            album={album}
            albums={albums}
            showMoreLabel={getMicroCopy({
              key: `music.showMoreInfo`,
              data: state?.value,
            })}
            showLessLabel={getMicroCopy({
              key: `music.showLessInfo`,
              data: state?.value,
            })}
          />
        </div>
      </MusicasWrapper>
    </div>
  )
}

// Components PropTypes
Musicas.propTypes = {
  sectionTitle: PropTypes.string,
  sectionCopy: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
  albums: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      year: PropTypes.string,
      type: PropTypes.string,
      spotifyUrl: PropTypes.string,
      soundcloudUrl: PropTypes.string,
      googleUrl: PropTypes.string,
      deezerUrl: PropTypes.string,
      appleUrl: PropTypes.string,
      tracks: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          author: PropTypes.string,
          duration: PropTypes.string,
          spotifyUrl: PropTypes.string,
          soundcloudUrl: PropTypes.string,
          googleUrl: PropTypes.string,
          deezerUrl: PropTypes.string,
          appleUrl: PropTypes.string,
        })
      ),
      image: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string,
          srcSet: PropTypes.string,
          srcWebp: PropTypes.string,
          srcSetWebp: PropTypes.string,
          sizes: PropTypes.string,
          aspectRatio: PropTypes.number,
          base64: PropTypes.string,
        }),
      }),
    })
  ),
  widget: PropTypes.bool,
}
Musicas.defaultProps = {
  sectionTitle: null,
  sectionCopy: null,
}

export default Musicas

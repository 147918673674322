// Dependencies
import React, { useEffect, useContext, useState } from 'react'
import Img from 'gatsby-image'
import { get } from 'lodash'
import PropTypes from 'prop-types'

// Helpers, Context
import { Context as PlayerContext } from '../../context/playerContext'
import { formatTime } from '../../helpers/utils'

// Styles
import {
  playerNextSVG,
  playerPlaySVG,
  playerPauseSVG,
  playerVolumeSVG,
  playerPrevSVG,
} from '../../assets/icons'

// Components
import { SectionGrid, SectionTitle } from '../Template/Template.styled'
import Social from '../Template/Social'

const Album = ({
  album,
  albums,
  index,
  compact = false,
  showMoreLabel,
  showLessLabel,
}) => {
  const [expanded, setExpanded] = useState(false)

  // Getting state and methods from Context
  const {
    state: {
      tracks,
      playingState,
      volume,
      currentAlbumId,
      currentTrack,
      currentTrackId,
      currentTrackDuration,
      elapsedPct,
      elapsedTime,
    },
    setTracks,
    seekPosition,
    playTrack,
    pauseTrack,
    setVolume,
    playNext,
    playPrev,
  } = useContext(PlayerContext)

  const setCurrentTrack = (track) => {
    playTrack({
      currentAlbumId: track.albumId,
      currentTrack: track,
      currentTrackId: track.trackId,
    })
  }

  const prevTrack = () => {
    let prevId = currentTrackId - 1
    let prevAlbumId = currentAlbumId
    if (prevId < 0) {
      prevAlbumId = currentAlbumId - 1
      if (prevAlbumId < 0) {
        prevId = albums[albums.length - 1].tracks.length - 1
        prevAlbumId = albums.length - 1
      } else {
        prevId = albums[prevAlbumId].tracks.length - 1
      }
    }

    playPrev({
      currentAlbumId: prevAlbumId,
      currentTrack: albums[prevAlbumId].tracks[prevId],
      currentTrackId: prevId,
    })
  }

  const nextTrack = () => {
    let nextId = currentTrackId + 1
    let nextAlbumId = currentAlbumId
    if (nextId >= albums[currentAlbumId].tracks.length) {
      nextId = 0
      nextAlbumId = currentAlbumId + 1
      if (nextAlbumId >= albums.length) {
        nextAlbumId = 0
      }
    }

    playNext({
      currentAlbumId: nextAlbumId,
      currentTrack: albums[nextAlbumId].tracks[nextId],
      currentTrackId: nextId,
    })
  }

  const togglePlay = (albumId) => {
    if (playingState === 'stopped') {
      playTrack({
        currentAlbumId: albumId,
        currentTrack: albums[albumId].tracks[0].trackId,
        currentTrackId: albums[albumId].tracks[0].trackId,
      })
    } else {
      pauseTrack({
        currentAlbumId: albumId,
        currentTrack: currentTrack,
        currentTrackId: currentTrackId,
      })
    }
  }

  const setPosition = (event) => {
    const e = event.nativeEvent
    let clickX
    if (e.layerX || e.layerY) {
      clickX = e.layerX
    } else if (e.offsetX || e.offsetY) {
      clickX = e.offsetX
    } else if (
      e.originalEvent &&
      (e.originalEvent.layerX || e.originalEvent.layerY)
    ) {
      clickX = e.originalEvent.layerX
    }
    const totalWidth = e.target.clientWidth
    const position = (currentTrackDuration * clickX) / totalWidth

    seekPosition({
      currentAlbumId: currentAlbumId,
      currentTrackId: currentTrackId,
      position,
    })
  }

  const albumCopyHtml =
    album && album.sectionCopy
      ? get(album.sectionCopy, 'childMarkdownRemark.html')
          .replace(/\r|\n|\r\n/g, '')
          .split('</p><p>')
          .map((item) => item.replace(/<\/?p>/g, ''))
      : []

  return (
    <div>
      {albumCopyHtml && (
        <SectionGrid noPadding>
          <div className="col-1-2">
            <h3>{album.title}</h3>
            {albumCopyHtml.map((par, i) => (
              <p
                key={'column_1_' + i}
                dangerouslySetInnerHTML={{ __html: par }}
              />
            ))}
          </div>
          <div className="col-1-2">
            <div
              className="album-release"
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <div className="album__image">
                <Img fluid={album.image.fluid} />
                <div className="album__social">
                  <Social links={album.links} />
                </div>
              </div>
              <div className="album__player">
                <div className="album__player__playlist">
                  <ul>
                    {album.tracks &&
                      (compact ? album.tracks.slice(0, 3) : album.tracks).map(
                        (track, j) => {
                          return tracks && tracks[index] && tracks[index][j] ? (
                            <li
                              className={
                                'track' +
                                (index === currentAlbumId &&
                                j === currentTrackId &&
                                playingState === 'playing'
                                  ? ' active'
                                  : '')
                              }
                              key={`${album.title}_${j}`}
                            >
                              <button
                                onClick={() =>
                                  index === currentAlbumId &&
                                  j === currentTrackId
                                    ? togglePlay(currentAlbumId)
                                    : setCurrentTrack(tracks[index][j])
                                }
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      index === currentAlbumId &&
                                      j === currentTrackId &&
                                      playingState === 'playing'
                                        ? playerPauseSVG
                                        : playerPlaySVG,
                                  }}
                                />
                                <div className="track__title">
                                  {track.title} <small>{track.author}</small>
                                </div>
                                <div className="track__duration">
                                  {track.duration}
                                </div>
                              </button>
                            </li>
                          ) : null
                        }
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </SectionGrid>
      )}
    </div>
  )
}

// Components PropTypes
const albumPropShape = PropTypes.shape({
  title: PropTypes.string,
  introCopy: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
  year: PropTypes.string,
  type: PropTypes.string,
  spotifyUrl: PropTypes.string,
  soundcloudUrl: PropTypes.string,
  googleUrl: PropTypes.string,
  deezerUrl: PropTypes.string,
  appleUrl: PropTypes.string,
  tracks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      author: PropTypes.string,
      duration: PropTypes.string,
      spotifyUrl: PropTypes.string,
      soundcloudUrl: PropTypes.string,
      googleUrl: PropTypes.string,
      deezerUrl: PropTypes.string,
      appleUrl: PropTypes.string,
    })
  ),
  image: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcWebp: PropTypes.string,
      srcSetWebp: PropTypes.string,
      sizes: PropTypes.string,
      aspectRatio: PropTypes.number,
      base64: PropTypes.string,
    }),
  }),
})
Album.propTypes = {
  albums: PropTypes.arrayOf(albumPropShape),
  album: albumPropShape,
  compact: PropTypes.bool,
}

export default Album

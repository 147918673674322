// Dependencies
import React, { useContext } from 'react'

// Components
import Hero from '../../components/Hero/Hero'
import AlbumPreview from '../../components/AlbumPreview/AlbumPreview'
import Bio from '../../components/Bio/Bio'
import Videos from '../../components/Videos/Videos'
import Social from '../../components/Social/Social'
import { default as SocialLinks } from '../../components/Template/Social'
import SocialButtons from '../../components/Template/SocialButtons'

// Styles
import '../../assets/index.css'
import {
  Section,
  SectionTitle,
  SectionIntro,
  SectionGrid,
  SectionDivider,
} from '../../components/Template/Template.styled'
import Countdown from './Countdown'

// Helpers
import { Context as MicroCopyContext } from '../../context/microcopyContext'
import { getMicroCopy } from '../../helpers/microcopy'

export const AlbumTemplate = ({
  general,
  about,
  music,
  videos,
  social,
  album,
}) => {
  const {
    state: { value: microcopy },
  } = useContext(MicroCopyContext)

  const monthsLabels = getMicroCopy({
    key: 'events.monthsLabels',
    data: microcopy,
  }).split(',')

  const formatDate = (date) => {
    var d = date.getDate()
    var m = monthsLabels[date.getMonth()]
    var y = date.getFullYear()
    return `${d} de ${m} de ${y}`
  }

  return (
    <>
      <Hero noParallax {...{ images: album.heroImage }} />
      <Countdown date={album.releaseDate} />
      <Section>
        <SectionTitle>
          {getMicroCopy({ key: 'music.release', data: microcopy })}
        </SectionTitle>
        <SectionIntro>
          <p>
            {getMicroCopy({
              key: 'music.releaseOnSentence',
              data: microcopy,
              tokens: {
                date: formatDate(new Date(album.releaseDate + ' 00:00:00')),
              },
            })}
          </p>
        </SectionIntro>
        <AlbumPreview {...{ ...music, album: album || music.albums[0] }} />
      </Section>
      <SectionDivider />
      <SectionGrid>
        <div className="col-1-2">
          <SectionTitle>{about.introTitle}</SectionTitle>
          <Bio {...about} widget />
        </div>
        <div className="col-1-2">
          <SectionTitle>{videos.introTitle}</SectionTitle>
          <Videos {...videos} widget size={1} inline random />
        </div>
      </SectionGrid>
      {/* <SectionGrid>
        <div className="col-1-2">
          <SectionTitle>{events.introTitle}</SectionTitle>
          <Agenda
            {...{ ...events, dates: dates.map(date => date.node) }}
            widget
          />
        </div>
        <div className="col-1-2">
          <SectionTitle>{images.introTitle}</SectionTitle>
          <Fotos {...images} widget />
        </div>
      </SectionGrid> */}
      <SectionDivider />
      <SectionGrid>
        <div className="col-1-1">
          <SectionTitle>{social.introTitle}</SectionTitle>
          {social.introCopy && (
            <SectionIntro noPadding>
              <p>{social.introCopy}</p>
            </SectionIntro>
          )}
          <div className="content social-nav social-nav--page">
            <div>
              <SocialLinks {...general} />
            </div>
            <div className="follow-links">
              <SocialButtons {...general} />
            </div>
          </div>
          <Social juicerFeedId={general.juicerFeedId} compact />
        </div>
      </SectionGrid>
    </>
  )
}

export default AlbumTemplate
